.survey-container {
    position: relative;
    padding: 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #22355d;
    z-index: 1; /* Ensure the background image is behind the content */
  }
  
  .survey-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/billy-huynh-v9bnfMCyKbg-unsplash-min.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.08; /* Adjust opacity to make the image barely visible */
    z-index: -1; /* Ensure the background image is behind the content */
  }
  
  .options-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%; /* Adjusted to fit better on mobile */
  }
  
  .btn-outline-light {
    width: 100%; /* Adjust width as needed */
    margin: 10px 0; /* Add margin to space buttons vertically */
    font-size: xx-large;
    padding: 15px;
  }
  
  .btn-outline-light.selected {
    background-color: #fff;
    color: #22355d; /* Marine blue */
  }
  
  .back-button {
    width: 60px; /* Adjust width to fit the icon */
    height: 60px; /* Adjust height to fit the icon */
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
  
  @media (max-width: 768px) {
    .options-container {
      width: 90%; /* Full width on mobile */
    }
  
    .btn-outline-light {
      font-size: large; /* Smaller font size for mobile */
    }
  
    .survey-container {
      padding: 10px;
    }
  }
  