body {
  background-color: #111111;
}

.live-page {
  background-color: #111111;
  color: #fff;
  font-family: 'Roboto', 'Arial', sans-serif;
  max-width: 90vw;
  margin: auto;
}

.live-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.header-icons {
  display: flex;
  gap: 20px;
}

.video-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.video-title {
  font-family: "Roboto", "Arial", sans-serif;
  margin-top: 10px;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
}

.viewers-count {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #aaaaaa;
  margin-top: 5px;
}

.pulsing-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  margin-right: 5px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.profile-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  margin-top: 20px;
  width: 100%;
}

.profile-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.uploader-name {
  font-size: 16px;
}

.subscribers-count {
  font-size: 14px;
  color: #aaaaaa;
}

.subscribe-button {
  font-size: 16px;
  color: #0f0f0f;
  background-color: #f1f1f1;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
}

.subscribe-button:hover {
  background-color: #dadada;
}

.video-interaction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.interaction-buttons {
  display: flex;
  gap: 15px;
}

.interaction-group {
  display: flex;
  align-items: center;
  background-color: #2e2e2e;
  padding: 5px 10px;
  border-radius: 20px;
  gap: 5px;
}

.interaction-group svg {
  height: 20px;
  width: 20px;
}

.text-sm {
  font-size: 14px;
}

.comments-section {
  margin-top: 20px;
  padding: 10px;
  width: 100%;
}

.comments-section h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.comment {
  padding: 15px;
  border-bottom: 1px solid #333;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.comment-content {
  display: flex;
  gap: 10px;
}

.comment-profile {
  display: flex;
  gap: 10px;
}

.comment-profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.comment-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.comment-author {
  font-size: 14px;
  font-weight: 500;
}

.comment-time {
  font-size: 12px;
  color: #aaaaaa;
  margin-left: 5px;
}

.comment-text {
  font-size: 14px;
  font-weight: 400;
}

.comment-interactions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.comment-interactions svg {
  height: 20px;
  width: 20px;
}

.comment-options {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Spinner CSS */
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease infinite;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* Load More Button CSS */
.load-more-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 1em;
  background-color: #00000000;
  color: white;
  border: solid;
  border-color: white;
  border-radius: 5px;
  cursor: pointer;
  border-width: 1px;
  transition: all 0.2s ease;
}

.load-more-button:hover {
  background-color: white;
  color: black;
}

/* Responsive CSS */
@media (max-width: 768px) {
  .live-page {
    max-width: 100vw;
    padding: 10px;
  }

  .live-header {
    gap: 10px;
    padding: 10px;
  }

  .video-section {
    padding: 10px;
  }

  .video-title {
    font-size: 18px;
    text-align: left;
  }

  .profile-section {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .video-interaction {
    gap: 10px;
    margin-top: 10px;
  }

  .interaction-buttons {
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .comments-section {
    padding: 10px;
  }

  .comment {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .comment-content {
    align-items: flex-start;
  }

  .comment-profile {
    gap: 10px;
  }
}
