@font-face {
  font-family: 'The Seasons';
  src: url('../../public/fonts/Fontspring-DEMO-theseasons-reg.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'The Seasons';
  src: url('../../public/fonts/Fontspring-DEMO-theseasons-it.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'The Seasons';
  src: url('../../public/fonts/Fontspring-DEMO-theseasons-bd.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

/* Add this CSS to handle safe-view */
.safe-view {
  padding-bottom: env(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  text-align: center;
  position: relative;
  transition: transform 0.5s;
}


.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: darkred;
  color: yellow;
  text-align: center;
  z-index: 10;
}

.homepage-mobile {
  position: relative;
  background-color: #22355d;
  color: white;
  font-family: 'Arial', sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
  z-index: 1; /* Ensure the content is above the background */
  padding-top: 30%;
}

.homepage-mobile::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/billy-huynh-v9bnfMCyKbg-unsplash-min.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.08; /* Adjust opacity to make the image barely visible */
  z-index: -1; /* Ensure the background image is behind the content */
}

.homepage {
  position: relative;
  background-color: #22355d;
  color: white;
  font-family: 'Arial', sans-serif;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
  z-index: 1; /* Ensure the content is above the background */
}

.homepage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/billy-huynh-v9bnfMCyKbg-unsplash-min.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.08; /* Adjust opacity to make the image barely visible */
  z-index: -1; /* Ensure the background image is behind the content */
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-block {
  text-align: center;
}

.header h1 {
  font-size: 48px;
}

.header p {
  font-size: 24px;
}

.logo {
  width: 200px;
  position: absolute;
  top: 10%;
  right: 5vw;
}


.mute-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
}

.video-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 900px;
  margin: -10px auto ;
  transition: all 0.5s ease;
}

.video-container-live {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 900px;
  margin: -10px auto ;
  transition: all 0.5s ease;
}

.video-container.video-ended video {
  width: 80%;
  height: auto;
  transition: all 0.5s ease;
}


/* Overlay for muted state */
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
  z-index: 1000; /* Above video but below other elements */
}

/* Style changes for unmute prompt */
/* Add this to your Live.css or relevant CSS file */
.unmute-prompt, .unmute-prompt-mobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.2em;
  text-align: center;
}

.video-container:hover .unmute-prompt {
  opacity: 0;
  transform: translate(0, 10px);
}

/* Add styles for chevrons */
.chevron-left, .chevron-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 70px;
  color: white;
  cursor: pointer;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
}

.chevron-left {
  left: -160px; /* Adjust as needed to position outside the player */
  transform: translateY(-50%) rotate(180deg); /* Point inward */
}

.chevron-right {
  right: -160px; /* Adjust as needed to position outside the player */
}

/* Add keyframes for bounce-left animation */
@keyframes bounce-left {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(-50%) translateX(0) rotate(180deg);
  }
  40% {
    transform: translateY(-50%) translateX(10px) rotate(180deg);
  }
  60% {
    transform: translateY(-50%) translateX(-10px) rotate(180deg);
  }
}

/* Add keyframes for bounce-right animation */
@keyframes bounce-right {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(-50%) translateX(0) rotate(180deg);
  }
  40% {
    transform: translateY(-50%) translateX(-10px) rotate(180deg);
  }
  60% {
    transform: translateY(-50%) translateX(10px) rotate(180deg);
  }
}

.chevron-left {
  animation: bounce-left 1.5s infinite;
}

.chevron-right {
  animation: bounce-right 1.5s infinite;
}

.video-container:not(.video-ended) .chevron-left, 
.video-container:not(.video-ended) .chevron-right {
  animation-play-state: running;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-out, visibility 0.5s ease-out;
}

/* Pause bounce animation when video is ended */
.video-container.video-ended .chevron-left, 
.video-container.video-ended .chevron-right {
  animation-play-state: paused;
}


video {
  width: 100%;
  height: auto;
  border: none;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  padding: 10px;
  z-index: 0;
}


.action-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: white;
  color: #22355d;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease, scale 0.5s ease;
  visibility: hidden;
  pointer-events: none;
}

.action-button-live {
  margin:  20px 0 30px 0;
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 30px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease, scale 0.5s ease;
  visibility: hidden;
  pointer-events: none;
}

.action-button.show {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  pointer-events: auto;
  animation: pulse 2s infinite;
}

.action-button-live.show {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  pointer-events: auto;
  animation: pulse 2s infinite;
}

.action-button.grow {
  background-color: rgb(5, 236, 5);
  color: #ffffff;
  transform: scale(1.3) translateY(0px); /* Grow the button by 30% */
  animation: pulseGrow 2s infinite; /* Continue the pulsing animation */
  transition: all 0.5s ease; /* Apply the transition for animation */
  margin-top: 50px;
}


.action-button:hover {
  background-color: #0056b3;
  color: white;
  transform: scale(1.05) translateY(0px);
}

.action-button:active {
  background-color: #004080;
  color: white;
  transform: scale(0.95) translateY(0px);
}

.action-button-live:hover {
  background-color: white;
  color: red;
  transform: scale(1.05) translateY(0px);
}

.action-button-live:active {
  background-color: #004080;
  color: white;
  transform: scale(0.95) translateY(0px);
}

button {
  padding: 10px 20px;
  background-color: white;
  color: blue;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.play-pause-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1.2em;
  text-align: center;
}

.pause-icon {
  animation: fadeIn 0.5s forwards;
}


/* Additional Responsive Adjustments */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    padding: 10px 0;
    transform: translateX(0);
  }

  .logo-mobile {
    position: absolute;
    width: 200px;
    bottom: 0;
    left: 0;
    
  }

  .logo {
    width: 200px;
    margin-left: 20px;
  }

  .header {
    flex-direction: column; /* Stack logo and text vertically on smaller screens */
    align-items: center; /* Center items */
  }

  .video-container {
    width: 90%; /* Slight margins */
  }

  video {
    max-height: 50vh; /* Limit video height on smaller devices */
  }

  .action-button, button {
    font-size: calc(20px + 1vw); /* Smaller, responsive font size */
  }

  .action-button-live, button {
    font-size: calc(20px + 1vw); /* Smaller, responsive font size */
    margin-left: auto;
    margin-right: auto;
  }

  .mute-icon {
    font-size: calc(16px + 1vw); /* Larger tap targets for mobile */
  }
}

/* Smaller devices, more aggressive adjustments */
@media (max-width: 480px) {
  .logo {
    width: 30vw; /* Larger proportional size */
  }

  .action-button, button {
    padding: 8px 16px; /* Smaller padding */
  }
}

  .action-button-live, button {
    padding: 8px 16px; /* Smaller padding */
    margin-left: auto;
    margin-right: auto;
  }


/* Loading Spinner */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.2);
  border-top: 8px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

/* Pulsing animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Pulsing animation */
@keyframes pulseGrow {
  0% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1.3);
  }
}

.custom-modal .modal-content {
  background-color: rgba(34, 53, 93, 0.9); /* Transparent #22355d background */
  color: white;
  position: relative;
}

.custom-modal .form-group {
  margin-bottom: 20px; /* Increase margin between form groups */
}

.custom-modal .form-control {
  margin-bottom: 10px; /* Increase margin between inputs */
}

.custom-modal .modal-header,
.custom-modal .modal-body,
.custom-modal .modal-footer {
  position: relative;
  z-index: 1;
}

.custom-modal .modal-header .modal-title,
.custom-modal .modal-body,
.custom-modal .modal-footer {
  color: white;
}

.custom-modal-live .modal-content {
  background-color: #111111; /* Transparent #22355d background */
  color: white;
  position: relative;
}

.custom-modal-live .form-group {
  margin-bottom: 20px; /* Increase margin between form groups */
}

.custom-modal-live .form-control {
  margin-bottom: 10px; /* Increase margin between inputs */
}

.custom-modal-live .modal-header,
.custom-modal-live .modal-body,
.custom-modal-live .modal-footer {
  position: relative;
  z-index: 1;
}

.custom-modal-live .modal-header .modal-title,
.custom-modal-live .modal-body,
.custom-modal-live .modal-footer {
  color: white;
}
