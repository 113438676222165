.comment-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  color: white;
  height: 100vh;
  padding-top: 60px; /* Add padding to account for the fixed header */
}

.live-header-form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #000;
  border-bottom: 1px solid #333;
  position: fixed;
  top: 0;
  z-index: 10;
}

.header-icons {
  display: flex;
  gap: 10px;
}

.comment-form-container {
  width: 100%;
  max-width: 500px;
  background-color: #222;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  margin: auto;
}

.comment-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
}

button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.bonus-text {
  color: #555;
  font-weight: bolder;
  font-size: x-large;
  text-align: center;
  margin-top: 20px;
}

.success-text {
  color: green;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}

/* Add these styles to your CommentForm.css */

.form-group input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: transparent;
  color: white;
  border: solid;
  border-radius: 4px;
  border-width: 1px;
  font-size: 16px;
  text-align: center;
}

.custom-file-upload:hover {
  background-color: #cc0000;
}

/* Add these styles to your CommentForm.css */

.picture-name {
  display: block;
  margin-top: 5px;
  color: #aaa;
  font-size: 14px;
}



/* Responsive styles */
@media (max-width: 768px) {
  .comment-form-container {
    padding: 15px;
    width: 90vw;
  }

  .bonus-text {
    font-size: large;
    padding: 20px;
  }

  .live-header-form {
    padding: 10px;
  }
}
